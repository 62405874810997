import * as yup from 'yup';
import { useMemo, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

import { formsApi } from 'src/api/forms';

import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { LeftIcon, RightIcon } from 'src/components/carousel/arrow-icons';

export const FormModal = ({ formId, settings, formName, open, onClose, currentFormIndex, formListCount, handleNextForm, handlePrevForm, handleSubmittedForm }) => {
  const brandId = localStorage.getItem("brandId");

  const [isSubmitting, setIsSubmitting]= useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const  validationObject = useMemo(()=> {
    let yupObj = {} ;
    settings?.filter((item)=> !item?.isOptional)?.forEach((item)=> {
      yupObj= {
        ...yupObj,
        [item?.id] : item?.inputType !==3 ? yup.string().required('This field is required.') : yup.array().min(1, "This field is required.").of(yup.string().required('Item is required'))
      }})
    return yupObj;
  }, [settings])

  const validationSchema = yup.object({
      ...validationObject
    })

  const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({ resolver: yupResolver(validationSchema)});

  useEffect(() => {
    if(settings?.length) {
      settings?.filter((item)=> !item?.isOptional)?.forEach((item) => {
        setValue(item.id, item?.inputType !==3? "": [])
      })
    }
  }, [settings])

  const onSubmit= (data)=> {
    setIsSubmitting(true);
    const result = settings.map(setting => ({
      ...setting,
      value: data[setting.id] || null
    }));
    const formData = new FormData();
    formData.append('internal_brand_id', brandId);
    formData.append('brand_form_id', formId);
    formData.append('settings', JSON.stringify(result));
    formsApi.submitForm(formData).then(() => {
      handleSubmittedForm(formId);
      enqueueSnackbar('Form is submitted successfully.', { variant: 'success' });
    }).catch(() => {
      enqueueSnackbar('Something went wrong.', { variant: 'error' });
    }).finally(()=> {
      setIsSubmitting(false);
    });
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <Stack sx={{ p: 4, minHeight: 600, justifyContent: 'space-between' }} spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
          <Stack alignItems="flex-end">
            <Typography variant="h4">Fill the forms and submit</Typography>
          </Stack>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
          <Stack direction="column" fontSize={20} mx={2} sx={{ height: '100%', minHeight: 500, flexGrow: 1, justifyContent: 'space-between'}}>
            <Stack direction='column' gap={1}>
              <Typography variant="h5">{formName??""}</Typography>
              <Scrollbar Scrollbar sx={{ maxHeight: 450, pr: 1.5, pl: 0.5 }}>
                {settings?.map(setting => {
                  if (setting.inputType === 1) {
                    return (
                      <Stack>
                        <Typography marginTop={3}>{setting.name}</Typography>
                        <TextField
                          variant='standard' 
                          sx={{ maxWidth: '100%', width: 1 }}
                          {...register(setting?.id)}
                          error={!!errors?.[setting?.id]?.message}
                          helperText={errors?.[setting?.id]?.message} 
                        />
                      </Stack>
                    );
                  } 
                  if ( setting.inputType === 2 && setting.options?.length > 0 ) { 
                    return (
                      <Stack>
                        <Typography marginTop={3}>{setting.name}</Typography>
                        <Controller
                          control={control}
                          name={setting?.id}
                          render={({field: { onChange}})=> (
                            <RadioGroup
                              sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}} 
                              name={setting.id}
                              onChange={e => onChange(e.target.value)} 
                            >
                              { 
                                setting.options.map(option => (
                                  <FormControlLabel 
                                    key={option.id} 
                                    control={
                                      <Radio size="normal" value={option.id} sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }} />
                                    } 
                                    label={option.option} 
                                  />
                                )) 
                              }
                            </RadioGroup>
                          )}
                        />
                        {errors?.[setting?.id]?.message?
                          <FormHelperText 
                            error={!!errors?.[setting?.id]?.message} 
                            sx={{ m: 0 }}>
                            {errors?.[setting?.id]?.message ??""}
                          </FormHelperText>
                        : null}
                      </Stack>
                    );
                  }
                  if ( setting.inputType === 3 && setting.options?.length > 0 ) { 
                    return (
                      <Stack>
                        <Typography marginTop={3}>{setting.name}</Typography>
                        <FormGroup>
                          <Stack direction='row' gap={0.5} sx={{ flexWrap: 'wrap'}}>
                            <Controller
                              control={control}
                              name={setting?.id}
                              render={({field: { value=[], onChange }})=> (
                                <>
                                {setting.options.map(option => (
                                  <FormControlLabel 
                                    key={option.id} 
                                    control={
                                      <Checkbox 
                                        size="normal" 
                                        checked={value?.includes(option?.id)}
                                        onChange={(e) => {
                                          const newValue = e.target.checked
                                            ? [...value, option.id]
                                            : value?.filter((v) => v !== option.id);
                                          onChange(newValue);
                                        }}
                                      />
                                    } 
                                    label={option.option} 
                                  />
                                ))}
                                </>
                              )}
                            />
                          </Stack>
                        </FormGroup>
                        {errors?.[setting?.id]?.message?
                          <FormHelperText 
                            error={!!errors?.[setting?.id]?.message} 
                            sx={{ m: 0 }}>
                            {errors?.[setting?.id]?.message ??""}
                          </FormHelperText>
                        : null}
                      </Stack>
                    );
                  }
                  return null;
                })}
              </Scrollbar>
            </Stack>
            <LoadingButton 
              loading={isSubmitting}
              color="primary" 
              variant="contained" 
              type='submit'
              sx={{marginTop: 2, width: 150, marginLeft: 'auto'}}
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
          <Button onClick={handlePrevForm} disabled={currentFormIndex === 0}>
            <LeftIcon />
          </Button>
          {`${currentFormIndex + 1} / ${formListCount}`}
          <Button onClick={handleNextForm} disabled={currentFormIndex >= formListCount - 1}>
            <RightIcon />
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
